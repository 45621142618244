import React, { useEffect, useState } from "react";
import "./Filter.css";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { IoIosArrowDown } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProductType,
  filterProductsByType,
} from "../../../Redux/productSlice";

const Filter = () => {
  const dispatch = useDispatch();
  const { selectedProductType } = useSelector((state) => state.product);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [fullList, setFullList] = useState([]);

  const limit = 12;

  const params = {
    limit,
    skip: (currentPage - 1) * limit,
  };

  useEffect(() => {
    dispatch(fetchProductType(params)).then((response) => {
      const newProductTypes = response.payload.data;

      // Filter out duplicates based on _id
      const filteredNewProductTypes = newProductTypes.filter(
        (newItem) =>
          !fullList.some((existingItem) => existingItem._id === newItem._id)
      );

      // Append only unique items to the full list
      setFullList((prevList) => [...prevList, ...filteredNewProductTypes]);

      // Update hasNextPage based on the API response
      setHasNextPage(response.payload.isNext);
    });
  }, [dispatch, currentPage]);

  const handleLoadMore = () => {
    if (hasNextPage) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handleFilterProductType = (type) => {
    dispatch(filterProductsByType(type));
  };

  return (
    <div>
      <div className="filterSection">
        <div className="filterCategories">
          <Accordion defaultExpanded disableGutters elevation={0}>
            <AccordionSummary
              expandIcon={<IoIosArrowDown size={20} />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{ padding: 0, marginBottom: 2 }}
            >
              <h5 className="filterHeading">Product Categories</h5>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              {fullList.map((type) => (
                <p
                  key={type._id}
                  style={{
                    color:
                      selectedProductType === type?.product_type
                        ? "#009ee3"
                        : "rgba(0, 0, 0, 0.87)",
                  }}
                  onClick={() => handleFilterProductType(type?.product_type)}
                >
                  {type?.product_type || "-"}
                </p>
              ))}
              {hasNextPage && (
                <button onClick={handleLoadMore}>Load More Categories</button>
              )}
            </AccordionDetails>
          </Accordion>
        </div>

        {/* Color Filter------------------- */}
        {/* <div className="filterColors">
          <Accordion defaultExpanded disableGutters elevation={0}>
            <AccordionSummary
              expandIcon={<IoIosArrowDown size={20} />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{ padding: 0, marginBottom: 2 }}
            >
              <h5 className="filterHeading">Color</h5>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <div className="filterColorBtn">
                {filterColors.map((color, index) => (
                  <button
                    key={index}
                    className={`colorButton ${
                      selectedColors.includes(color) ? 'selected' : ''
                    }`}
                    style={{
                      backgroundColor: color,
                    }}
                    onClick={() => handleColorChange(color)}
                  />
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
        </div> */}

        {/* Sizes Filter ----------------- */}
        {/* <div className="filterSizes">
          <Accordion defaultExpanded disableGutters elevation={0}>
            <AccordionSummary
              expandIcon={<IoIosArrowDown size={20} />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{ padding: 0, marginBottom: 2 }}
            >
              <h5 className="filterHeading">Sizes</h5>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <div className="sizeButtons">
                {filterSizes.map((size, index) => (
                  <button
                    key={index}
                    className={`sizeButton ${
                      selectedSizes.includes(size) ? 'selected' : ''
                    }`}
                    onClick={() => handleSizeChange(size)}
                  >
                    {size}
                  </button>
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
        </div> */}

        {/* Brands Filter ---------------- */}
        {/* <div className="filterBrands">
          <Accordion defaultExpanded disableGutters elevation={0}>
            <AccordionSummary
              expandIcon={<IoIosArrowDown size={20} />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{ padding: 0, marginBottom: 2 }}
            >
              <h5 className="filterHeading">Brands</h5>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <div className="searchBar">
                <BiSearch className="searchIcon" size={20} color="#767676" />
                <input
                  type="text"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={e => setSearchTerm(e.target.value)}
                />
              </div>

              <div className="brandList">
                {filteredBrands.length > 0 ? (
                  filteredBrands.map((brand, index) => (
                    <div className="brandItem" key={index}>
                      <input
                        type="checkbox"
                        name="brand"
                        id={`brand-${index}`}
                        className="brandRadio"
                      />
                      <label htmlFor={`brand-${index}`} className="brandLabel">
                        {brand.name}
                      </label>
                      <span className="brandCount">{brand.count}</span>
                    </div>
                  ))
                ) : (
                  <div className="notFoundMessage">Not found</div>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </div> */}

        {/* Price Filter ----------------- */}
        {/* <div className="filterPrice">
          <Accordion defaultExpanded disableGutters elevation={0}>
            <AccordionSummary
              expandIcon={<IoIosArrowDown size={20} />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{ padding: 0, marginBottom: 2 }}
            >
              <h5 className="filterHeading">Price</h5>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <Slider
                getAriaLabel={() => 'Temperature range'}
                value={value}
                onChange={handleChange}
                valueLabelDisplay="auto"
                valueLabelFormat={value => `$${value}`}
                sx={{
                  color: 'black',
                  '& .MuiSlider-thumb': {
                    backgroundColor: 'white',
                    border: '2px solid black',
                    width: 18,
                    height: 18,
                  },
                }}
              />

              <div className="filterSliderPrice">
                <div className="priceRange">
                  <p>
                    Min Price: <span>${value[0]}</span>
                  </p>
                  <p>
                    Max Price: <span>${value[1]}</span>
                  </p>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div> */}
      </div>
    </div>
  );
};

export default Filter;
