import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { LoggedInCheck, registerUser } from "../../../Redux/authSlice";
import { Alert, CircularProgress } from "@mui/material";
import SnackbarComponent from "../../../Components/Snackbar";
import useSnackbar from "../../../hooks/useSnackbar";

function LoginForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { snackbar, showSnackbar, hideSnackbar } = useSnackbar();

  const { loading, error, isAuthenticated, logged } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(LoggedInCheck());
    }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    if (logged === "pass" && isAuthenticated) {
      navigate("/");
    }
  }, [logged, isAuthenticated, navigate]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    dispatch(registerUser(data))
      .then(({ payload }) => {
        showSnackbar(
          payload.message || "Registered successful!",
          payload.success ? "success" : "error"
        );
        setTimeout(() => {
          navigate("/auth");
        }, 1000);
      })
      .catch((error) => {
        showSnackbar(error.message || "Register failed!", "error");
      });
  };

  return (
    <div className="loginSignUpTabsContentRegister">
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <input
          type="text"
          placeholder="First Name *"
          {...register("fName", {
            required: "First Name is required",
          })}
          required
        />

        <input
          type="text"
          placeholder="Last Name *"
          {...register("lName", {
            required: "Last Name is required",
          })}
          required
        />

        <input
          type="text"
          placeholder="Company Name *"
          {...register("companyName", {
            required: "Company Name is required",
          })}
          required
        />

        <input
          type="text"
          placeholder="Company Reg No. *"
          {...register("companyRegNo", {
            required: "Company Reg No is required",
          })}
          required
        />

        <input
          type="email"
          {...register("email", {
            required: "Email is required",
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
              message: "Enter a valid email address",
            },
          })}
          placeholder="Email address *"
          required
          autoComplete="new-password"
        />
        {errors.email && <p className="error-msg">{errors.email.message}</p>}

        <input
          type="password"
          placeholder="Password *"
          {...register("password", {
            required: "Password is required",
            minLength: {
              value: 6,
              message: "Password must be at least 6 characters long",
            },
          })}
          autoComplete="new-password"
        />
        {errors.password && (
          <p className="error-msg">{errors.password.message}</p>
        )}

        <input
          type="text"
          placeholder="Telephone Number *"
          {...register("telephoneNo", {
            required: "Telephone Number is required",
          })}
          required
        />

        <input type="text" placeholder="Fax Number *" {...register("faxNo")} />

        <input
          type="text"
          placeholder="Website *"
          {...register("website", {
            required: "Website is required",
            pattern: {
              value: /^(https?:\/\/)?([\w-]+\.)+[\w-]{2,4}(\/[^\s]*)?$/,
              message: "Enter a valid website URL",
            },
          })}
          required
        />

        <input
          type="text"
          placeholder="Contact (Trade Ref 1) *"
          {...register("contactTrade1", {
            required: "Contact (Trade Ref 1) is required",
          })}
          required
        />

        <input
          type="text"
          placeholder="Contact (Trade Ref 2) *"
          {...register("contactTrade2", {
            required: "Contact (Trade Ref 2) is required",
          })}
          required
        />

        <input
          type="text"
          placeholder="Tel No. (Trade Ref 1) *"
          {...register("telNoTrade1", {
            required: "Tel No. (Trade Ref 1) is required",
          })}
          required
        />

        <input
          type="text"
          placeholder="Tel No. (Trade Ref 2) *"
          {...register("telNoTrade2", {
            required: "Tel No. (Trade Ref 2) is required",
          })}
          required
        />

        <input
          type="email"
          placeholder="Email (Trade Ref 1) *"
          {...register("emailTrade1", {
            required: "Email (Trade Ref 1) is required",
          })}
          required
        />

        <input
          type="email"
          placeholder="Email (Trade Ref 2) *"
          {...register("emailTrade2", {
            required: "Email (Trade Ref 2) is required",
          })}
          required
        />

        <input
          type="text"
          placeholder="Bankers Name *"
          {...register("bankersName", {
            required: "Bankers Name is required",
          })}
          required
        />

        <input
          type="text"
          placeholder="Account Number *"
          {...register("accountNo", {
            required: "Account Number is required",
          })}
          required
        />

        <input
          type="text"
          placeholder="Sort Code *"
          {...register("sortCode", {
            required: "Sort Code is required",
          })}
          required
        />

        <input
          type="text"
          placeholder="Name of Proprietor *"
          {...register("nameOfProprietor", {
            required: "Name of Proprietor is required",
          })}
          required
        />

        <input
          type="text"
          placeholder="Accounts Contact *"
          {...register("accountsContact", {
            required: "Accounts Contact is required",
          })}
          required
        />

        <input
          type="text"
          placeholder="Accounts Contact Position *"
          {...register("accountsContactPosition", {
            required: "Accounts Contact Position is required",
          })}
          required
        />

        <input
          type="text"
          placeholder="We Will Require a Credit Limit of (£) *"
          {...register("requireCreditLimit", {
            required: "We Will Require a Credit Limit of (£) is required",
          })}
          required
        />

        <p>
          Your personal data will be used to support your experience throughout
          this website, to manage access to your account, and for other purposes
          described in our
          <Link
            to="/terms"
            style={{ textDecoration: "none", color: "#c32929" }}
          >
            {" "}
            privacy policy
          </Link>
          .
        </p>

        <button type="submit" disabled={loading}>
          {loading ? "Processing..." : "Register"}
        </button>
      </form>
      {error && (
        <Alert variant="filled" severity="error" style={{ marginTop: 20 }}>
          {error}
        </Alert>
      )}

      {loading && (
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <CircularProgress />
        </div>
      )}

      <SnackbarComponent
        message={snackbar.message}
        severity={snackbar.severity}
        open={snackbar.open}
        onClose={hideSnackbar}
      />
    </div>
  );
}

export default LoginForm;
