import React, { useEffect, useState } from "react";
import "./MyAccountPage.css";
import { Grid, Skeleton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getOrderList } from "../../Redux/orderSlice";
import { logoutUser } from "../../Redux/authSlice";
import useSnackbar from "../../hooks/useSnackbar";
import SnackbarComponent from "../Snackbar";
import { useNavigate } from "react-router-dom";

const MyAccountPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { orders, loading } = useSelector((state) => state.order);
  const { snackbar, showSnackbar, hideSnackbar } = useSnackbar();

  const [activeMenu, setActiveMenu] = useState("profile");
  const [expandedOrder, setExpandedOrder] = useState(null);

  useEffect(() => {
    if (activeMenu === "order") {
      let params = {
        limit: 20,
        skip: 0,
        sort: "createdAt",
        sortType: "desc",
      };
      dispatch(getOrderList(params));
    }
  }, [dispatch, activeMenu]);

  const toggleOrderDetails = (orderNo) => {
    setExpandedOrder((prevOrder) => (prevOrder === orderNo ? null : orderNo));
  };

  const renderOrderDetails = (order) => (
    <tr>
      <td colSpan={5}>
        <table className="order-details-table">
          <thead>
            <tr>
              <th>Product</th>
              <th>Price</th>
              <th>Quantity</th>
              <th>SKU</th>
            </tr>
          </thead>
          <tbody>
            {order.products.map((productItem) => {
              const product = productItem.productId[0];
              const variant = product.variants[0];
              return (
                <tr key={variant.id}>
                  <td>{product.title}</td>
                  <td>${variant.price}</td>
                  <td>{productItem.quantity}</td>
                  <td>{variant.sku}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </td>
    </tr>
  );

  const handleLogout = () => {
    dispatch(logoutUser())
      .then(() => {
        navigate("/");
        showSnackbar("Logout successful!", "success");
      })
      .catch((error) => {
        showSnackbar(error.message || "Logout failed!", "error");
      });
  };

  return (
    <section className="my-account container">
      <h2 className="page-title">My Account</h2>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <ul className="account-nav">
            <li>
              <a
                href="#profile"
                onClick={(e) => {
                  e.preventDefault();
                  setActiveMenu("profile");
                }}
                className={`menu-link menu-link_us-s ${
                  activeMenu === "profile" ? "menu-link_active" : ""
                }`}
              >
                Profile
              </a>
            </li>
            <li>
              <a
                href="#order"
                onClick={(e) => {
                  e.preventDefault();
                  setActiveMenu("order");
                }}
                className={`menu-link menu-link_us-s ${
                  activeMenu === "order" ? "menu-link_active" : ""
                }`}
              >
                Orders
              </a>
            </li>
            <li>
              <a
                href="#logout"
                onClick={(e) => {
                  e.preventDefault();
                  handleLogout();
                }}
                className={`menu-link menu-link_us-s ${
                  activeMenu === "logout" ? "menu-link_active" : ""
                }`}
              >
                Logout
              </a>
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} md={9}>
          <div className="page-content my-account__dashboard">
            {activeMenu === "profile" && (
              <div className="loginSignUpTabsContentRegister">
                <form autoComplete="off">
                  <input
                    type="text"
                    placeholder="Name *"
                    value={user?.name}
                    readOnly
                  />

                  <input
                    type="email"
                    placeholder="Email address *"
                    value={user?.email}
                    readOnly
                  />
                </form>
              </div>
            )}

            {activeMenu === "order" && (
              <table className="orders-table">
                <thead>
                  <tr>
                    <th>Order</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Total</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                {loading ? (
                  <tbody>
                    <tr>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton height={50} />
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {orders.map((order) => {
                      const orderNo = order._id.orderNo;
                      const date = new Date(order.createdAt).toLocaleDateString(
                        "en-US",
                        {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        }
                      );
                      const status = order.status;
                      const totalAmount = order.amount.toFixed(2);
                      const itemCount = order.products.length;
                      const isExpanded = expandedOrder === orderNo;

                      return (
                        <React.Fragment>
                          <tr key={orderNo}>
                            <td>#{orderNo}</td>
                            <td>{date}</td>
                            <td>{status}</td>
                            <td>
                              ${totalAmount} for {itemCount}{" "}
                              {itemCount > 1 ? "items" : "item"}
                            </td>
                            <td>
                              <button
                                className="btn btn-primary"
                                onClick={() => toggleOrderDetails(orderNo)}
                              >
                                VIEW
                              </button>
                            </td>
                          </tr>
                          {isExpanded && renderOrderDetails(order)}
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                )}
              </table>
            )}
          </div>
        </Grid>
      </Grid>

      <SnackbarComponent
        message={snackbar.message}
        severity={snackbar.severity}
        open={snackbar.open}
        onClose={hideSnackbar}
      />
    </section>
  );
};

export default MyAccountPage;
