import React, { useEffect, useState } from "react";
import "./ShoppingCart.css";
import { useSelector, useDispatch } from "react-redux";
import {
  cartShippingStatus,
  placeOrder,
  productListOfCart,
  removeItemFromCart,
  updateCurrentCart,
  updateItemFromCart,
} from "../../Redux/cartSlice";

import { MdOutlineClose } from "react-icons/md";

import { Link } from "react-router-dom";

import success from "../../Assets/success.png";
import CartSkeleton from "./CartSkeleton";
import SnackbarComponent from "../Snackbar";
import useSnackbar from "../../hooks/useSnackbar";
import { LinearProgress, Switch } from "@mui/material";

const ShoppingCart = () => {
  const { isAuthenticated, user } = useSelector((state) => state.auth);

  const cartItems = useSelector((state) => state.cart.items);
  const shippingStatus = useSelector((state) => state.cart.shippingStatus);
  const orderDetails = useSelector((state) => state.cart.orderDetails);

  const loading = useSelector((state) => state.cart.listLoader);
  const totalPrice = useSelector((state) => state.cart.totalAmount);

  const dispatch = useDispatch();
  const { snackbar, showSnackbar, hideSnackbar } = useSnackbar();

  const [activeTab, setActiveTab] = useState("cartTab1");
  const [payments, setPayments] = useState(false);
  const [loader, setLoader] = useState(false);

  const [errors, setErrors] = useState({}); // For tracking form field errors
  const [billingDetails, setBillingDetails] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    country: "United Kingdom",
    streetAddress: "",
    apartment: "",
    city: "",
    postcode: "",
    phone: "",
    email: "",
    orderNotes: "",
  });

  useEffect(() => {
    if (isAuthenticated) dispatch(productListOfCart());
  }, [dispatch, isAuthenticated]);

  const handleTabClick = (tab) => {
    if (tab === "cartTab1" || cartItems.length > 0) {
      setActiveTab(tab);
    }
  };

  const handleQuantityChange = (item, quantity) => {
    if (quantity >= 1) {
      dispatch(updateCurrentCart({ item: item, quantity: quantity }));
    }
    if (quantity >= 1 && quantity <= item?.variantId?.inventory_quantity) {
      dispatch(updateItemFromCart({ _id: item._id, quantity: quantity }));
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const currentDate = new Date();

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const [selectedPayment, setSelectedPayment] = useState(
    "Direct Bank Transfer"
  );

  const handlePaymentChange = (e) => {
    setSelectedPayment(e.target.value);
  };

  const getSubTotal = (item) => {
    let price = item.variantId?.price ? Number(item.variantId?.price) : 0;
    return `£` + (item.quantity * price).toFixed(2);
  };

  const handleRemoveCart = (item) => {
    dispatch(removeItemFromCart(item._id))
      .then(({ payload }) => {
        showSnackbar(
          payload.message || "Item removed from cart successfully!",
          payload.success ? "success" : "error"
        );
        dispatch(productListOfCart());
      })
      .catch((error) => {
        showSnackbar(error.message || "Failed to remove from cart!", "error");
      });
  };

  const handleChangeShipping = (event) => {
    dispatch(cartShippingStatus(event.target.checked));
  };

  const getTotalVAT = () => {
    let subTotal = totalPrice;
    let totalDiscount =
      (subTotal * (user?.discount ? user?.discount : 0)) / 100;

    let shippingTotal = subTotal - totalDiscount;
    let shipping = 0;
    if (shippingTotal >= 200) {
      shipping = 0;
    } else if (shippingTotal < 200) {
      shipping = 10;
    } else {
      shipping = shippingStatus ? 10 : 0;
    }

    let totalAmt = shipping + subTotal - totalDiscount;
    let totalVAT = (totalAmt * 20) / 100;
    return totalVAT.toFixed(2);
  };

  const getTotalDiscount = () => {
    let subTotal = totalPrice || 0;

    let totalDiscount =
      (subTotal * (user?.discount ? user?.discount : 0)) / 100;
    return totalDiscount.toFixed(2);
  };

  const getTotalAmount = () => {
    let subTotal = totalPrice;
    let totalDiscount =
      (subTotal * (user?.discount ? user?.discount : 0)) / 100;

    let shippingTotal = subTotal - totalDiscount;
    let shipping = 0;
    if (shippingTotal >= 200) {
      shipping = 0;
    } else if (shippingTotal < 200) {
      shipping = 10;
    } else {
      shipping = shippingStatus ? 10 : 0;
    }

    let totalAmt = shipping + subTotal - totalDiscount;
    let totalVAT = (totalAmt * 20) / 100;

    let TotalAmount = subTotal - totalDiscount + shipping + totalVAT;
    return TotalAmount.toFixed(2);
  };

  const getTotalShipping = () => {
    let subTotal = totalPrice;
    let totalDiscount =
      (subTotal * (user?.discount ? user?.discount : 0)) / 100;
      
    let shippingTotal = subTotal - totalDiscount;
    let shipping = 0;
    if (shippingTotal >= 200) {
      shipping = 0;
    } else if (shippingTotal < 200) {
      shipping = 10;
    } else {
      shipping = shippingStatus ? 10 : 0;
    }
    return shipping;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBillingDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value, // Update specific field
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", // Clear error message when field changes
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!billingDetails.firstName.trim())
      newErrors.firstName = "First name is required";
    if (!billingDetails.lastName.trim())
      newErrors.lastName = "Last name is required";
    if (!billingDetails.streetAddress.trim())
      newErrors.streetAddress = "Street address is required";
    if (!billingDetails.apartment.trim())
      newErrors.apartment = "Apartment/suite is required";
    if (!billingDetails.city.trim()) newErrors.city = "City is required";
    if (!billingDetails.postcode.trim())
      newErrors.postcode = "Postcode/ZIP is required";
    if (!billingDetails.phone.trim())
      newErrors.phone = "Phone number is required";
    if (!billingDetails.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(billingDetails.email)) {
      newErrors.email = "Email format is invalid"; // Basic email format validation
    }
    return newErrors;
  };

  const placeOrderFun = () => {
    setLoader(true);
    const formattedData = {
      products: cartItems.map((item) => ({
        productId: item.productId._id,
        variantId: item.variantId.id,
        quantity: item.quantity,
      })),
      amount: getTotalAmount(),
      discount: getTotalDiscount(),
      shipping: shippingStatus ? 10 : 0,
      vat: getTotalVAT(),
      address: billingDetails,
    };

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setLoader(false);
      setErrors(validationErrors); // Display errors if any
    } else {
      dispatch(placeOrder(formattedData))
        .then(({ payload }) => {
          showSnackbar(
            payload.message || "Order placed successfully!",
            payload.success ? "success" : "error"
          );
          dispatch(productListOfCart());
          handleTabClick("cartTab3");
          window.scrollTo({ top: 0, behavior: "smooth" });
          setPayments(true);
          setLoader(false);
        })
        .catch((error) => {
          setLoader(false);
          showSnackbar(error.message || "Failed to place your order!", "error");
        });
    }
  };

  return (
    <div>
      <div className="shoppingCartSection">
        <h2>Cart</h2>

        <div className="shoppingCartTabsContainer">
          <div className={`shoppingCartTabs £{activeTab}`}>
            <button
              className={activeTab === "cartTab1" ? "active" : ""}
              onClick={() => {
                handleTabClick("cartTab1");
                setPayments(false);
              }}
            >
              <div className="shoppingCartTabsNumber">
                <h3>01</h3>
                <div className="shoppingCartTabsHeading">
                  <h3>Shopping Bag</h3>
                  <p>Manage Your Items List</p>
                </div>
              </div>
            </button>
            <button
              className={activeTab === "cartTab2" ? "active" : ""}
              onClick={() => {
                handleTabClick("cartTab2");
                setPayments(false);
              }}
              disabled={cartItems.length === 0}
            >
              <div className="shoppingCartTabsNumber">
                <h3>02</h3>
                <div className="shoppingCartTabsHeading">
                  <h3>Shipping and Checkout</h3>
                  <p>Checkout Your Items List</p>
                </div>
              </div>
            </button>
            <button
              className={activeTab === "cartTab3" ? "active" : ""}
              onClick={() => {
                handleTabClick("cartTab3");
              }}
              disabled={cartItems.length === 0 || payments === false}
            >
              <div className="shoppingCartTabsNumber">
                <h3>03</h3>
                <div className="shoppingCartTabsHeading">
                  <h3>Confirmation</h3>
                  <p>Review And Submit Your Order</p>
                </div>
              </div>
            </button>
          </div>
          {loading ? (
            <CartSkeleton />
          ) : (
            <div className="shoppingCartTabsContent">
              {/* tab1 */}
              {activeTab === "cartTab1" && (
                <div className="shoppingBagSection">
                  <div className="shoppingBagTableSection">
                    {/* For Desktop Devices */}
                    <table className="shoppingBagTable">
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th />
                          <th>Price</th>
                          <th>Quantity</th>
                          <th>Subtotal</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {cartItems.length > 0 ? (
                          cartItems.map((item) => (
                            <tr key={item._id}>
                              <td data-label="Product">
                                <div className="shoppingBagTableImg">
                                  <Link to="/product" onClick={scrollToTop}>
                                    <img
                                      src={item.variantImage?.src}
                                      alt={item.productId?.title}
                                    />
                                  </Link>
                                </div>
                              </td>
                              <td data-label="">
                                <div className="shoppingBagTableProductDetail">
                                  <Link to="/product" onClick={scrollToTop}>
                                    <h4>{item.productId?.title}</h4>
                                    {item.productId?.variants?.length > 1 && (
                                      <p>Variant: {item.variantId?.title}</p>
                                    )}
                                  </Link>
                                </div>
                              </td>
                              <td
                                data-label="Price"
                                style={{ textAlign: "center" }}
                              >
                                £{item.variantId?.price}
                              </td>
                              <td data-label="Quantity">
                                <div className="ShoppingBagTableQuantity">
                                  <button
                                    onClick={() =>
                                      handleQuantityChange(
                                        item,
                                        item.quantity - 1
                                      )
                                    }
                                  >
                                    -
                                  </button>
                                  <input
                                    type="text"
                                    min="1"
                                    max="20"
                                    value={item.quantity}
                                    onChange={(e) =>
                                      handleQuantityChange(
                                        item,
                                        parseInt(e.target.value)
                                      )
                                    }
                                  />
                                  <button
                                    onClick={() =>
                                      handleQuantityChange(
                                        item,
                                        item.quantity + 1
                                      )
                                    }
                                  >
                                    +
                                  </button>
                                </div>
                              </td>
                              <td data-label="Subtotal">
                                <p
                                  style={{
                                    textAlign: "center",
                                    fontWeight: "500",
                                  }}
                                >
                                  {getSubTotal(item)}
                                </p>
                              </td>
                              <td data-label="" style={{ cursor: "pointer" }}>
                                <MdOutlineClose
                                  onClick={() => handleRemoveCart(item)}
                                />
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6">
                              <div className="shoppingCartEmpty">
                                <span>Your cart is empty!</span>
                                <Link to="/shop" onClick={scrollToTop}>
                                  <button>Shop Now</button>
                                </Link>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>

                    <div className="shipping-status">
                      <span>Pickup</span>
                      <Switch
                        defaultValue={shippingStatus}
                        onChange={handleChangeShipping}
                      />
                      <span>Shipping</span>
                    </div>

                    {/* For Mobile devices */}

                    <div className="shoppingBagTableMobile">
                      {cartItems.length > 0 ? (
                        <>
                          {cartItems.map((item) => (
                            <div key={item.productID}>
                              <div className="shoppingBagTableMobileItems">
                                <div className="shoppingBagTableMobileItemsImg">
                                  <Link to="/product" onClick={scrollToTop}>
                                    <img
                                      src={item.variantImage?.src}
                                      alt={item.productId?.title}
                                    />
                                  </Link>
                                </div>
                                <div className="shoppingBagTableMobileItemsDetail">
                                  <div className="shoppingBagTableMobileItemsDetailMain">
                                    <Link to="/product" onClick={scrollToTop}>
                                      <h4>{item.productId?.title}</h4>
                                      <p>
                                        {item.productId?.variants?.length >
                                          1 && (
                                          <p>
                                            Variant: {item.variantId?.title}
                                          </p>
                                        )}
                                      </p>
                                    </Link>
                                    <div className="shoppingBagTableMobileQuantity">
                                      <button
                                        onClick={() =>
                                          handleQuantityChange(
                                            item,
                                            item.quantity - 1
                                          )
                                        }
                                      >
                                        -
                                      </button>
                                      <input
                                        type="text"
                                        min="1"
                                        max="20"
                                        value={item.quantity}
                                        onChange={(e) =>
                                          handleQuantityChange(
                                            item,
                                            parseInt(e.target.value)
                                          )
                                        }
                                      />
                                      <button
                                        onClick={() =>
                                          handleQuantityChange(
                                            item,
                                            item.quantity + 1
                                          )
                                        }
                                      >
                                        +
                                      </button>
                                    </div>
                                    <span>£{item.variantId?.price}</span>
                                  </div>
                                  <div className="shoppingBagTableMobileItemsDetailTotal">
                                    <MdOutlineClose
                                      size={20}
                                      onClick={() =>
                                        dispatch(handleRemoveCart(item))
                                      }
                                    />
                                    <p>
                                      £{item.quantity * item.variantId?.price}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      ) : (
                        <div className="shoppingCartEmpty">
                          <span>Your cart is empty!</span>
                          <Link to="/shop" onClick={scrollToTop}>
                            <button>Shop Now</button>
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="shoppingBagTotal">
                    <h3>Cart Totals</h3>
                    <table className="shoppingBagTotalTable">
                      <tbody>
                        <tr>
                          <th>Subtotal</th>
                          <td>£{totalPrice.toFixed(2)}</td>
                        </tr>
                        <tr>
                          <th>Discount</th>
                          <td>£{getTotalDiscount()}</td>
                        </tr>
                        <tr>
                          <th>Shipping</th>
                          <td>
                            <div className="shoppingBagTotalTableCheck">
                              <p>£{getTotalShipping()}</p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>VAT</th>
                          <td>£{getTotalVAT()}</td>
                        </tr>
                        <tr>
                          <th>Total</th>
                          <td>£{getTotalAmount()}</td>
                        </tr>
                      </tbody>
                    </table>
                    <button
                      onClick={() => {
                        handleTabClick("cartTab2");
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                      disabled={cartItems.length === 0}
                    >
                      Proceed to Checkout
                    </button>
                  </div>
                </div>
              )}

              {/* tab2 */}
              {activeTab === "cartTab2" && (
                <div className="checkoutSection">
                  <div className="checkoutDetailsSection">
                    <h4>Billing Details</h4>
                    <div className="checkoutDetailsForm">
                      <form>
                        <div className="checkoutDetailsFormRow">
                          <input
                            type="text"
                            name="firstName"
                            placeholder="First Name"
                            value={billingDetails.firstName}
                            onChange={handleInputChange}
                          />

                          <input
                            type="text"
                            name="lastName"
                            placeholder="Last Name"
                            value={billingDetails.lastName}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="error-flex">
                          {errors.firstName && (
                            <p style={{ color: "red" }}>{errors.firstName}</p>
                          )}
                          {errors.lastName && (
                            <p style={{ color: "red" }}>{errors.lastName}</p>
                          )}
                        </div>

                        <input
                          type="text"
                          name="companyName"
                          placeholder="Company Name (optional)"
                          value={billingDetails.companyName}
                          onChange={handleInputChange}
                        />

                        <select
                          name="country"
                          id="country"
                          value={billingDetails.country}
                          onChange={handleInputChange}
                        >
                          <option value="United Kingdom">United Kingdom</option>
                        </select>

                        <input
                          type="text"
                          name="streetAddress"
                          placeholder="Street Address*"
                          value={billingDetails.streetAddress}
                          onChange={handleInputChange}
                        />
                        {errors.streetAddress && (
                          <p style={{ color: "red" }}>{errors.streetAddress}</p>
                        )}

                        <input
                          type="text"
                          name="apartment"
                          placeholder="Apartment, suite, etc. (optional)"
                          value={billingDetails.apartment}
                          onChange={handleInputChange}
                        />
                        {errors.apartment && (
                          <p style={{ color: "red" }}>{errors.apartment}</p>
                        )}

                        <input
                          type="text"
                          name="city"
                          placeholder="Town / City *"
                          value={billingDetails.city}
                          onChange={handleInputChange}
                        />
                        {errors.city && (
                          <p style={{ color: "red" }}>{errors.city}</p>
                        )}

                        <input
                          type="text"
                          name="postcode"
                          placeholder="Postcode / ZIP *"
                          value={billingDetails.postcode}
                          onChange={handleInputChange}
                        />
                        {errors.postcode && (
                          <p style={{ color: "red" }}>{errors.postcode}</p>
                        )}

                        <input
                          type="text"
                          name="phone"
                          placeholder="Phone *"
                          value={billingDetails.phone}
                          onChange={handleInputChange}
                        />
                        {errors.phone && (
                          <p style={{ color: "red" }}>{errors.phone}</p>
                        )}

                        <input
                          type="email"
                          name="email"
                          placeholder="Your Mail *"
                          value={billingDetails.email}
                          onChange={handleInputChange}
                        />
                        {errors.email && (
                          <p style={{ color: "red" }}>{errors.email}</p>
                        )}

                        <textarea
                          name="orderNotes"
                          cols={30}
                          rows={8}
                          placeholder="Order Notes (Optional)"
                          value={billingDetails.orderNotes}
                          onChange={handleInputChange}
                        />
                      </form>
                    </div>
                  </div>
                  <div className="checkoutPaymentSection">
                    <div className="checkoutPaymentContainer">
                      <label>
                        <input
                          type="radio"
                          name="payment"
                          value="Direct Bank Transfer"
                          defaultChecked
                          onChange={handlePaymentChange}
                        />
                        <div className="checkoutPaymentMethod">
                          <span>Direct Bank Transfer</span>
                          <p>
                            Make your payment directly into our bank account.
                            Please use your Order ID as the payment
                            reference.Your order will not be shipped until the
                            funds have cleared in our account.
                          </p>
                        </div>
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="payment"
                          value="Check Payments"
                          onChange={handlePaymentChange}
                        />
                        <div className="checkoutPaymentMethod">
                          <span>Check Payments</span>
                          <p>
                            Phasellus sed volutpat orci. Fusce eget lore mauris
                            vehicula elementum gravida nec dui. Aenean aliquam
                            varius ipsum, non ultricies tellus sodales eu. Donec
                            dignissim viverra nunc, ut aliquet magna posuere
                            eget.
                          </p>
                        </div>
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="payment"
                          value="Cash on delivery"
                          onChange={handlePaymentChange}
                        />
                        <div className="checkoutPaymentMethod">
                          <span>Cash on delivery</span>
                          <p>
                            Phasellus sed volutpat orci. Fusce eget lore mauris
                            vehicula elementum gravida nec dui. Aenean aliquam
                            varius ipsum, non ultricies tellus sodales eu. Donec
                            dignissim viverra nunc, ut aliquet magna posuere
                            eget.
                          </p>
                        </div>
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="payment"
                          value="Paypal"
                          onChange={handlePaymentChange}
                        />
                        <div className="checkoutPaymentMethod">
                          <span>Paypal</span>
                          <p>
                            Phasellus sed volutpat orci. Fusce eget lore mauris
                            vehicula elementum gravida nec dui. Aenean aliquam
                            varius ipsum, non ultricies tellus sodales eu. Donec
                            dignissim viverra nunc, ut aliquet magna posuere
                            eget.
                          </p>
                        </div>
                      </label>
                      <div className="policyText">
                        Your personal data will be used to process your order,
                        support your experience throughout this website, and for
                        other purposes described in our{" "}
                        <Link to="/terms" onClick={scrollToTop}>
                          Privacy Policy
                        </Link>
                        .
                      </div>
                    </div>
                    <button onClick={placeOrderFun}>Place Order</button>
                    {loader && <LinearProgress />}
                  </div>
                </div>
              )}

              {/* tab3 */}
              {activeTab === "cartTab3" && (
                <div className="orderCompleteSection">
                  <div className="orderComplete">
                    <div className="orderCompleteMessage">
                      <div className="orderCompleteMessageImg">
                        <img src={success} alt="" />
                      </div>
                      <h3>Your order is completed!</h3>
                      <p>Thank you. Your order has been received.</p>
                    </div>
                    <div className="orderInfo">
                      <div className="orderInfoItem">
                        <p>Date</p>
                        <h4>{formatDate(currentDate)}</h4>
                      </div>
                      <div className="orderInfoItem">
                        <p>Total</p>
                        <h4>
                          £
                          {orderDetails?.amount
                            ? orderDetails?.amount?.toFixed(2)
                            : 0}
                        </h4>
                      </div>
                      <div className="orderInfoItem">
                        <p>Payment Method</p>
                        <h4>{selectedPayment}</h4>
                      </div>
                    </div>
                    <div className="orderTotalContainer">
                      <h3>Order Details</h3>
                      <div className="orderItems">
                        <table>
                          <thead>
                            <tr>
                              <th>PRODUCTS</th>
                              <th>SUBTOTALS</th>
                            </tr>
                          </thead>
                          <tbody>
                            {orderDetails?.products &&
                              orderDetails?.products.map((items) => (
                                <tr>
                                  <td>
                                    {items.productId?.title} x {items.quantity}
                                    {items.productId?.variants?.length > 1 && (
                                      <p>
                                        Variant:{" "}
                                        {items.productId?.variants[0]?.title}
                                      </p>
                                    )}
                                  </td>
                                  <td>
                                    £
                                    {items.productId?.variants[0]?.price *
                                      items.quantity}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="orderTotal">
                        <table>
                          <tbody>
                            {shippingStatus && (
                              <tr>
                                <th>Shipping</th>
                                <td>
                                  <div className="shoppingBagTotalTableCheck">
                                    <p>
                                      £{" "}
                                      {orderDetails?.shipping
                                        ? orderDetails?.shipping
                                        : 0}
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            )}
                            <tr>
                              <th>VAT</th>
                              <td>
                                £ {orderDetails?.vat ? orderDetails?.vat : 0}
                              </td>
                            </tr>
                            <tr>
                              <th>Discount</th>
                              <td>
                                £{" "}
                                {orderDetails?.discount
                                  ? orderDetails?.discount
                                  : 0}
                              </td>
                            </tr>
                            <tr>
                              <th>Total</th>
                              <td>
                                £{" "}
                                {orderDetails?.amount
                                  ? orderDetails?.amount
                                  : 0}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <SnackbarComponent
        message={snackbar.message}
        severity={snackbar.severity}
        open={snackbar.open}
        onClose={hideSnackbar}
      />
    </div>
  );
};

export default ShoppingCart;
