import React, { useEffect, useState } from "react";
import "./ShopDetails.css";
import { useDispatch, useSelector } from "react-redux";
import Filter from "../Filters/Filter";
import { Link, useNavigate } from "react-router-dom";
import { IoFilterSharp, IoClose } from "react-icons/io5";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa6";
import { FaCartPlus } from "react-icons/fa";
import { fetchProductList } from "../../../Redux/productSlice";
import ProductSkeleton from "../../Skeleton/ProductSkeleton";
import { addProductToCart, productListOfCart } from "../../../Redux/cartSlice";
import useSnackbar from "../../../hooks/useSnackbar";
import SnackbarComponent from "../../Snackbar";

const ShopDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { snackbar, showSnackbar, hideSnackbar } = useSnackbar();

  const { products, loading, selectedProductType } = useSelector(
    (state) => state.product
  );
  const { user, isAuthenticated, logged } = useSelector((state) => state.auth);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);

  const limit = 12;

  useEffect(() => {
    let params = {
      limit,
      skip: (currentPage - 1) * limit,
    };
    if (selectedProductType) params.product_type = selectedProductType;
    dispatch(fetchProductList(params)).then((response) => {
      setHasNextPage(response.payload.isNext);
    });
    scrollToTop();
  }, [dispatch, currentPage, selectedProductType]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  const handleNextPage = () => {
    if (hasNextPage) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleAddToCart = (product) => {
    let quantity = product.variants[0].inventory_quantity || 0
    let variantId = product.variants[0].id
    
    if (user?.success && isAuthenticated && logged === "pass") {
      if (quantity > 0) {
        const productDetails = {
          productId: product._id,
          variantId: variantId,
          quantity: 1,
        };
        dispatch(addProductToCart(productDetails))
          .then(({ payload }) => {
            showSnackbar(
              payload.message || "Added to cart!",
              payload.success ? "success" : "error"
            );
            dispatch(productListOfCart());
          })
          .catch((error) => {
            showSnackbar(
              error.message || "Failed to added into cart!",
              "error"
            );
          });
      } else {
        showSnackbar("Please add quantity!", "warning");
      }
    } else {
      showSnackbar(
        "You must log in first to add products to your shopping cart!",
        "warning"
      );
      setTimeout(() => {
        navigate("/auth");
      }, 1000);
    }
  };

  return (
    <div>
      <div className="shopDetails">
        <div className="shopDetailMain">
          <div className="shopDetails__left">
            <Filter />
          </div>
          <div className="shopDetails__right">
            <div className="shopDetailsSorting">
              <div className="shopDetailsBreadcrumbLink">
                <Link to="/" onClick={scrollToTop}>
                  Home
                </Link>
                &nbsp;/&nbsp;
                <Link to="/Product">The Shop</Link>
              </div>
              <div className="filterLeft" onClick={toggleDrawer}>
                <IoFilterSharp />
                <p>Filter</p>
              </div>
              <div className="shopDetailsSort">
                <select name="sort" id="sort">
                  <option value="default">Default Sorting</option>
                  <option value="Featured">Featured</option>
                  <option value="bestSelling">Best Selling</option>
                  <option value="a-z">Alphabetically, A-Z</option>
                  <option value="z-a">Alphabetically, Z-A</option>
                  <option value="lowToHigh">Price, Low to high</option>
                  <option value="highToLow">Price, high to low</option>
                  <option value="oldToNew">Date, old to new</option>
                  <option value="newToOld">Date, new to old</option>
                </select>
                <div className="filterRight" onClick={toggleDrawer}>
                  <div className="filterSeprator" />
                  <IoFilterSharp />
                  <p>Filter</p>
                </div>
              </div>
            </div>
            <div className="shopDetailsProducts">
              <div className="shopDetailsProductsContainer">
                {loading ? (
                  <ProductSkeleton />
                ) : (
                  products &&
                  products.map((product) => (
                    <div className="sdProductContainer" key={product.id}>
                      <div className="sdProductImages">
                        <Link
                          to={`/product/${product._id}`}
                          onClick={scrollToTop}
                        >
                          <img
                            src={product.images[0]?.src}
                            alt={product.title}
                            // className="sdProduct_front"
                          />
                          {product?.images[1] && product?.images[1]?.src && (
                            <img
                              src={product.images[1]?.src}
                              alt={product.title}
                              className="sdProduct_back"
                            />
                          )}
                        </Link>

                        {product?.variants?.length > 1 ? (
                          <Link
                            className="quick_link"
                            to={`/product/${product._id}`}
                          >
                            Quick Shop
                          </Link>
                        ) : product?.variants[0]?.inventory_quantity > 0 ? (
                          <h4 onClick={()=>handleAddToCart(product)}>Add to Cart</h4>
                        ) : (
                          <h4>Sold Out</h4>
                        )}
                      </div>
                      <div
                        className="sdProductImagesCart"
                        // onClick={() => dispatch(addToCart(product))}
                      >
                        <FaCartPlus />
                      </div>
                      <div className="sdProductInfo">
                        <div className="sdProductCategoryWishlist">
                          <p>{product.product_type}</p>
                        </div>
                        <div className="sdProductNameInfo">
                          <Link
                            to={`/product/${product._id}`}
                            onClick={scrollToTop}
                          >
                            <h5>{product.title}</h5>
                          </Link>

                          <p>£{product.variants[0].price}</p>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
            <div className="shopDetailsPagination">
              <div
                className={
                  currentPage === 1
                    ? "sdPaginationPrev disabled"
                    : "sdPaginationPrev"
                }
                onClick={currentPage > 1 ? handlePrevPage : null}
              >
                <p>
                  <FaAngleLeft />
                  Prev
                </p>
              </div>
              <div className="sdPaginationNumber">
                <div className="paginationNum">{currentPage}</div>
              </div>
              <div
                className={
                  !hasNextPage
                    ? "sdPaginationNext disabled"
                    : "sdPaginationNext"
                }
                onClick={hasNextPage ? handleNextPage : null}
              >
                <p>
                  Next
                  <FaAngleRight />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Drawer */}
      <div className={`filterDrawer ${isDrawerOpen ? "open" : ""}`}>
        <div className="drawerHeader">
          <p>Filter By</p>
          <IoClose onClick={closeDrawer} className="closeButton" size={26} />
        </div>
        <div className="drawerContent">
          <Filter />
        </div>
      </div>

      <SnackbarComponent
        message={snackbar.message}
        severity={snackbar.severity}
        open={snackbar.open}
        onClose={hideSnackbar}
      />
    </div>
  );
};

export default ShopDetails;
