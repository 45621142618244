import React from "react";
import "./CollectionBox.css";

import { Link } from "react-router-dom";

const CollectionBox = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <div className="collection">
        <div className="collectionLeft">
          <div className="box-content">
            <h3 className="col-h3">
              <span>New</span> Products
            </h3>
            <div className="col-link">
              <Link to="/shop" onClick={scrollToTop}>
                <h5>Shop Now</h5>
              </Link>
            </div>
          </div>
        </div>
        <div className="collectionRight">
          <div className="collectionTop">
            <div className="box-content">
              <h3 className="col-h3">
                <span>Catalogue</span>
              </h3>
              <div className="col-link">
                <Link to="/catalogue" onClick={scrollToTop}>
                  <h5>View Now</h5>
                </Link>
              </div>
            </div>
          </div>
          <div className="collectionBottom">
            <div className="box1">
              <div className="box-content">
                <h3 className="col-h3">
                  <span>Clearance</span>
                </h3>
                <div className="col-link">
                  <Link to="/shop" onClick={scrollToTop}>
                    <h5>Shop Now</h5>
                  </Link>
                </div>
              </div>
            </div>
            <div className="box2">
              <div className="box-content">
                <h3 className="col-h3">
                  <span>Free</span> Delivery
                </h3>
                <div className="col-link">
                  <Link to="/shop" onClick={scrollToTop}>
                    <h5>Shop Now</h5>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollectionBox;
