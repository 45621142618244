import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import {
  addToCartListApi,
  addToCartProductApi,
  addToCartRemoveApi,
  addToCartUpdateApi,
  placeOrderApi,
} from "../Services/cartServices";

export const addProductToCart = createAsyncThunk(
  "cart/addProductToCart",
  async (data, { rejectWithValue }) => {
    try {
      const response = await addToCartProductApi(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const productListOfCart = createAsyncThunk(
  "cart/productListOfCart",
  async (data, { rejectWithValue }) => {
    try {
      const response = await addToCartListApi(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeItemFromCart = createAsyncThunk(
  "cart/removeItemFromCart",
  async (data, { rejectWithValue }) => {
    try {
      const response = await addToCartRemoveApi(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateItemFromCart = createAsyncThunk(
  "cart/updateItemFromCart",
  async (data, { rejectWithValue }) => {
    try {
      const response = await addToCartUpdateApi(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const placeOrder = createAsyncThunk(
  "order/placeOrder",
  async (data, { rejectWithValue }) => {
    try {
      const response = await placeOrderApi(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    items: [],
    loading: false,
    totalAmount: 0,
    listLoader: false,
    shippingStatus: false,
    orderDetails: null,
  },
  reducers: {
    updateCurrentCart(state, action) {
      const currentState = current(state);
      const updatedItem = action.payload.item;

      const existingItemIndex = currentState.items.findIndex(
        (item) =>
          item.productId._id === updatedItem?.productId?._id &&
          Number(item.variantId.id) === Number(updatedItem?.variantId?.id)
      );

      if (existingItemIndex !== -1) {
        state.items[existingItemIndex].quantity = action.payload?.quantity;
      }

      state.totalAmount = currentState.items.reduce(
        (total, item) => total + item.variantId?.price * item.quantity,
        0
      );
    },
    cartShippingStatus(state, action) {
      state.shippingStatus = action.payload;
    },
  },

  extraReducers: (builder) => {
    // Add Product to Cart
    builder
      .addCase(addProductToCart.pending, (state) => {
        state.loading = true;
      })
      .addCase(addProductToCart.fulfilled, (state, action) => {
        state.loading = false;
        // state.items = action.payload?.data[0]
        //   ? action.payload.data[0]?.variants
        //   : [];
        // state.totalAmount = action.payload?.data[0]
        //   ? action.payload.data[0]?.total
        //   : 0;
      })
      .addCase(addProductToCart.rejected, (state, action) => {
        state.loading = false;
      });

    // List Products of Cart
    builder
      .addCase(productListOfCart.pending, (state) => {
        state.listLoader = true;
      })
      .addCase(productListOfCart.fulfilled, (state, action) => {
        state.listLoader = false;
        state.items = action.payload?.data[0]
          ? action.payload?.data[0]?.variants
          : [];
        state.totalAmount = action.payload?.data[0]
          ? action.payload?.data[0]?.total
          : 0;
      })
      .addCase(productListOfCart.rejected, (state, action) => {
        state.listLoader = false;
      });

    // Remove Cart item
    builder
      .addCase(removeItemFromCart.pending, (state) => {
        state.listLoader = true;
      })
      .addCase(removeItemFromCart.rejected, (state) => {
        state.listLoader = false;
      });

    // Placed order
    builder.addCase(placeOrder.fulfilled, (state, action) => {
      state.orderDetails = action.payload?.data;
    });
  },
});

export const { updateCurrentCart, cartShippingStatus } = cartSlice.actions;

export default cartSlice.reducer;
